import axios from 'axios';
import {getReduxState, getReduxStore} from "../utils/redux";
import {setLoading} from "../storage/slicers/applicationSlice";
import {updateAuth} from "../storage/slicers/accountingSlice";


function getAuthorization(){
    let headers = {};
    const state = getReduxState();
    const credentials = state.account.credentials;
    if(credentials){
        headers.Authorization = `Bearer ${credentials}`
    }
    return headers
}

const NODE_ENV = process.env.NODE_ENV;


const getClient = ()=>{
  const api =  axios.create({
    baseURL: ( NODE_ENV === "development" )?`http://localhost:8000/api`:`${document.location.origin}/api`,
    headers: {
      ...getAuthorization()
    },
  });

  const store = getReduxStore();

  api.interceptors.request.use((config)=>{
    store.dispatch(setLoading(true));
    return config
  },(error)=>{
    store.dispatch(setLoading(false));
    return Promise.reject(error);
  });

  api.interceptors.response.use(
    (response)=>{
      store.dispatch(setLoading(false));
      store.dispatch(updateAuth())
      return response;
    }, 
    (error)=>{
      store.dispatch(setLoading(false));
      return Promise.reject(error)
    }
  );

  return api;
}

export default getClient;