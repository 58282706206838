import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listRecordTask, getAddRecordSavingStatus, getRecords, getZone,  createRecordTask} from '../../storage/slicers/modelsSlice';
import {getClient} from "../../api";
import {
    Container, Table, CardBody, Card, Button, InputGroup, InputGroupText, Input, Row, Col
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useNavigate, useParams} from 'react-router-dom';
import { CgSearch } from "react-icons/cg";
import { DNSRow } from "../components/dnsrow";
import { DNSForm } from "../components/forms";


function DNSListPage(props){
    let { id } = useParams();
    const zone = useSelector(getZone(id))
    const DNSs = useSelector(getRecords)
    const [adding, setAdding] = useState(false)
    const [searchFieldValue, setSearchFieldValue] = useState("")
    const [lastSearchValue, setLastSearchValue] = useState("")
    const [debounceTimeout, setDebounceTimeout] = useState(null)
    const dispatch = useDispatch()
    const navigator = useNavigate();
    
    const {saving, error} = useSelector(getAddRecordSavingStatus)
    const [isSaving, setIsSaving] = useState(saving)
    const [errors, setErrors] = useState(error)


    useEffect(()=>{
        dispatch(
            listRecordTask({zone_id:id, query:{}})
        )
    }, [zone])

    useEffect(()=>{
        dispatch(
            listRecordTask({zone_id:id, query:{}})
        )
    }, [])


    useEffect(()=>{
        setIsSaving(saving)
    }, [saving])

    useEffect(()=>{
        setErrors(error)
    }, [error])

    function getAdding(){
        return Boolean(
            adding || errors || isSaving
        )
    }

    function performSearch(){
        let query = {}
        if (lastSearchValue === searchFieldValue){
            return 
        }
        if(searchFieldValue.toString().trim().length){
            query.fullname = searchFieldValue.toString()
            query.content = searchFieldValue.toString()
            query._op = 'OR'
        }
        setLastSearchValue(searchFieldValue)
        dispatch(
            listRecordTask({ zone_id: id, query: query })
        )
    }

    function debounce(timeout){
        const wrapper = (func) => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout)
            }
            setDebounceTimeout(
                setTimeout(func, timeout)
            )
        }
        return wrapper
    }

    function searchChange(val){
        setSearchFieldValue(val.trim())
        debounce(1000)(performSearch)
    }
    

    return <Container className="zone-container">
        { (zone?.id)?
        <Card >
            <CardBody>
                <p>Gerênciamento de DNS do domínio <b>{zone.name}</b></p>
                <Row
                    className="m-0"
                >
                    <Col
                        className='px-2'
                        style={{
                            maxWidth: "7em"
                        }}
                    >
                        <Button
                            className="m-0"
                            color={saving?'light':getAdding()?'danger':"primary"}
                            size="sm"
                            disabled={saving}
                            onClick={e=>{
                                setAdding(!getAdding())
                                setErrors(null)
                                setIsSaving(false)
                                e.target.blur()
                            }}
                        >
                            {(saving?'Salvando...':getAdding())?'- Cacelar':'+ Adicionar'}
                        </Button>
                    </Col>
                    <Col
                        className='px-2'
                    >
                        <InputGroup
                            size="sm"
                            className='borderless'
                        >
                            <InputGroupText>
                                <CgSearch/>
                            </InputGroupText>
                            <Input type='text' onChange={e=>{
                                searchChange(e.currentTarget.value)
                            }} onKeyDown={(e)=>{
                                if(e.code.toLowerCase() === 'enter' ){
                                    e.preventDefault()
                                    debounce(0)(performSearch)
                                    return false
                                }
                                return true
                            }} value={searchFieldValue}>
                            </Input>
                        </InputGroup>
                    </Col>
                </Row>
                {
                    getAdding()&&
                    <Card className="px-4 mt-4">
                        <CardBody>
                            <Row>
                                <Table
                                    style={{
                                        border: 'solid 0px transparent',
                                        marginBottom: 0
                                    }}
                                >
                                    <tbody>
                                        <DNSForm
                                            onSave={(zone)=>{
                                                dispatch(
                                                    createRecordTask(zone)
                                                )
                                                setAdding(false)
                                            }}
                                            error={error}
                                            zone={zone}
                                            dns={null}
                                        />
                                    </tbody>
                                </Table>
                            </Row>
                        </CardBody>
                    </Card>
                }
                <Row className="px-4">
                    <Table
                        size="12" 
                        className='mt-4'
                    >
                        <thead
                        >
                            <tr>
                                <td><b>Tipo</b></td>
                                <td><b>Nome</b></td>
                                <td><b>Conteúdo</b></td>
                                <td><b>TTL</b></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            DNSs.map((dns,idx)=>{
                                return <DNSRow
                                    key={idx}
                                    zone={zone}
                                    dns={dns}
                                />
                            })
                            }
                        </tbody>
                    </Table>
                </Row>
            </CardBody>
        </Card>
        :<></>
        }
    </Container>
}


export default DNSListPage;