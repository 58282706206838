import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getRootName } from '../../../storage/slicers/accountingSlice'
import {
    FormGroup, Label,
    Input, Button,
    Col, Row, FormText,
    FormFeedback
} from 'reactstrap';



function ZoneForm(props){
    const onSave = props.onSave || (()=>{})
    const [zoneName, setZoneName] = useState('')
    const [zoneType, setZoneType] = useState(true)
    const zoneRoot = useSelector(getRootName)

    useEffect(()=>{
        if(props.error){
            Object.getOwnPropertyNames(props.error).forEach((val,idx)=>{
                if(['zone'].includes(val)){
                    return;
                }
                let erro_list = typeof props.error[val] === 'string'?[props.error[val]]:props.error[val];
                for(let err of erro_list){
                    toast.error(
                        <div>
                            <h5>{val}</h5>
                            <p>{err}</p>
                        </div>,
                        {
                            toastId: val,
                            delay: 0
                        }
                    )
                }
            })
        }
    }, [props.error])


    return <Row>
        <Col xs="12">
            <FormGroup
                className='position-relative'
            >
                <Label><b>Nome</b></Label>
                <Input
                    value={zoneName}
                    type='text'
                    onChange={e => { setZoneName(e.target.value) }}
                    invalid={
                        Boolean(props.error?.zone)
                    }
                />
                <FormFeedback
                >
                    {
                        Array(props.error?.zone || [])?.map((err, idx) => {
                            return <React.Fragment key={idx}>{err}<br /></React.Fragment>
                        })
                    }
                </FormFeedback>
                <small className="form-text text-muted">Se local, será concatenado com <b>"{zoneRoot}"</b>.</small>
            </FormGroup>
        </Col>
        <Col xs="12">
            <FormGroup
                className='position-relative'
                switch
            >
                <Input
                    type='switch'
                    checked={zoneType}
                    onChange={e => { setZoneType(!zoneType) }}
                />
                <Label className='ps-2 mb-0'>Local</Label><br/>
                <FormText>Se não marcado, a zona será tratada como uma zona global.</FormText>
            </FormGroup>
        </Col>
        <hr/>
        <Col xs="12" className='text-end'>
            <Button color="success" onClick={()=>{
                if(!zoneName.trim()) return;
                onSave({
                    zone: zoneName,
                    is_local: zoneType
                })
            }}>Salvar</Button>
        </Col>
    </Row>
}


export default ZoneForm;