import React, {useState} from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {
    Nav, NavbarBrand, NavbarToggler,
    Button,Collapse, Container, NavItem, } from 'reactstrap';
import { logout } from '../../storage/slicers/accountingSlice';


function NavBarComponent(props){
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    return <div className="navbar-top navbar-horizontal navbar navbar-expand-md">
        <Container className="px-3 mx-0">
            <NavbarBrand
                href="/"
                onClick={(e)=>{
                    e.preventDefault();
                    navigate('/app', {replace: true});
                }}
            >DNS Manager</NavbarBrand>
            {props.isAuthenticated &&<>
                <NavbarToggler onClick={()=>{setOpen(!open)}} />
                <Collapse isOpen={open} navbar>
                    <Nav navbar>
                        <NavItem>
                            <Link
                                to="/app/zone"
                                className="nav-link"
                            >
                                Zonas
                            </Link>
                        </NavItem>
                        <NavItem>
                            <a
                                className="nav-link"
                                href='/admin/'
                            >
                                Admin
                            </a>
                        </NavItem>
                        <NavItem>
                            <Link
                                to="/app/"
                                className="nav-link"
                                onClick={
                                    (e) => {
                                        dispatch(logout())
                                    }
                                }
                            >
                                Sair
                            </Link>
                        </NavItem>
                    </Nav>
                </Collapse>
            </>}
        </Container>
    </div>
}

export default NavBarComponent;