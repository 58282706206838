import getClient from './client';



export default class RecordService{

  static create(record){
    return new Promise((resolve, reject) =>{
        getClient().post(
          "/zone/"+record.zone_id+'/dns_records',
          record
        ).then((response)=>{
          resolve({
            data: response.data,
            id: undefined,
            zone_id: undefined
          })
        }).catch((e)=>{
          reject({
            data: e
          })
        })
    });
    }

    static list(zone_id, query){
        return new Promise((resolve, reject) =>{
            getClient().get(
              "/zone/"+zone_id+'/dns_records',
              { params: query }
            ).then((response)=>{
              resolve({
                data: response.data,
                id: undefined,
                zone_id: undefined
              })
            }).catch((e)=>{
              reject({
                data: e
              })
            })
        });
    }
    
    static read(zone_id, record_id){
        return new Promise((resolve, reject) =>{
            getClient().get(
              "/zone/"+zone_id+'/dns_records/'+record_id
            ).then((response)=>{
              resolve({
                data: response.data
                })
            }).catch((e)=>{
              reject({
                data: e
              })
            })
        });
    }

    static update(record){
        return new Promise((resolve, reject) =>{
            getClient().patch(
              "/zone/"+record.zone_id+'/dns_records/'+record.id,
              {
                ...record,
                id: undefined,
                zone_id: undefined
              }
            ).then((response)=>{
              resolve({
                data: response.data
                })
            }).catch((e)=>{
              reject({
                data: e
              })
            })
        });
    }

    static delete(record){
        return new Promise((resolve, reject) =>{
            getClient().delete(
                "/zone/"+record.zone_id+'/dns_records/'+record.id,
            ).then((response)=>{
              resolve({data: {zone_id: record.zone_id, id: record.id }})
            }).catch((e)=>{
              reject({
                data: e
              })
            })
        });
    }
    
    static enable(record){
        return new Promise((resolve, reject) =>{
            getClient().patch(
              "/zone/"+record.zone_id+'/dns_records/'+record.id+'/enable'
            ).then((response)=>{
              resolve({
                data: response.data
                })
            }).catch((e)=>{
              reject({
                data: e
              })
            })
        });
    }

    static disable(record){
        return new Promise((resolve, reject) =>{
            getClient().patch(
              "/zone/"+record.zone_id+'/dns_records/'+record.id+'/disable'
            ).then((response)=>{
              resolve({
                data: response.data
              })
            }).catch((e)=>{
              reject({
                data: e
              })
            })
        });
    }

}