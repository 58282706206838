import {logout} from "../slicers/accountingSlice";
import  secureLocalStorage  from  "react-secure-storage";

export const authMiddleware = (store) => (next) => (action) => {
  if (action.type === 'accounting/login/fulfilled'&&action.payload) {
    const data = {
      credentials: action.payload.credentials,
      lastValidation: action.payload.lastValidation
    }
    if(data.credentials && data.lastValidation){
      secureLocalStorage.setItem('_autentication_state', JSON.stringify(action.payload));
    }
  } else if (logout.match(action)) {
    secureLocalStorage.removeItem('_autentication_state');
  }
  return next(action);
};