import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import "./css/base.scss"
import AppRoutes from "./app/routes"



function App() {
  return (
    <AppRoutes/>
  );
}

export default App;
