import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    FormGroup, Label,
    Input, Button,
    FormFeedback,
    Row, Col
} from 'reactstrap';



function DNSForm(props){
    const zone = props.zone;
    const onSave = props.onSave || (()=>{})
    const dns = props.dns;
    const [type, setType] = useState(dns?.type || 'A')
    const [name, setName] = useState(dns?.name || '')
    const [priority, setPriority] = useState((dns?.type === 'MX')?parseInt(dns?.content?.split(/\s+/)[0])||'0':'0')
    const [content, setContent] = useState(((dns?.type === 'MX') ? dns?.content?.split(/\s+/)[1] : dns?.content) || '')
    const [ttl, setTTL] = useState(dns?.ttl || 3600)


    useEffect(()=>{
        if(props.error){
            Object.getOwnPropertyNames(props.error).forEach((val,idx)=>{
                if(['ttl', 'name', 'content', 'type'].includes(val)){
                    return;
                }
                let erro_list = typeof props.error[val] === 'string'?[props.error[val]]:props.error[val];
                for(let err of erro_list){
                    toast.error(
                        <div>
                            <h5>{val}</h5>
                            <p>{err}</p>
                        </div>,
                        {
                            toastId: val,
                            delay: 0
                        }
                    )
                }
            })
        }
    }, [props.error])


    function getContent(){
        if(type === 'MX')
            return `${priority || '0'}   ${content}`
        return content
    }


    return <tr>
        <td colSpan="5">
            <Row>
                <Col xs={12} md={2}>
                    <b><FormGroup
                        className='position-relative w-100'
                    >
                        <Label>Tipo</Label>
                        <select
                            value={type}
                            onChange={e=>{setType(e.target.value)}}
                            className='form-select'
                            invalid={
                                Boolean(props.error?.type)||undefined
                            }
                        >
                            <option value="A">A</option>
                            <option value="AAAA">AAAA</option>
                            <option value="CNAME">CNAME</option>
                            <option value="MX">MX</option>
                            <option value="WKS">WKS</option>
                            <option value="NS">NS</option>
                            <option value="SOA">SOA</option>
                            <option value="TXT">TXT</option>
                        </select>
                        <FormFeedback
                            //tooltip
                        >
                        {Array(props.error?.type||[])?.map((err, idx)=>{
                            return  <React.Fragment key={idx}> {err}<br/></React.Fragment>
                        }) }
                    </FormFeedback>
                    </FormGroup></b>
                </Col>
                <Col xs={12} md={3}>
                    <b><FormGroup
                        className='position-relative w-100'
                    >
                        <Label>Nome</Label>
                        <Input
                            value={name}
                            type='text'
                            onChange={e=>{setName(e.target.value)}}
                            invalid={
                                Boolean(props.error?.name)
                            }
                        />
                        <FormFeedback
                            //tooltip
                        >
                        {
                            Array(props.error?.name||[])?.map((err, idx)=>{
                                return <React.Fragment key={idx}>{err}<br/></React.Fragment>
                            })
                        }
                    </FormFeedback>
                    </FormGroup></b>
                </Col>
                <Col xs={12} md={3}>
                    <b><FormGroup
                        className='position-relative w-100'
                    >
                        <Label>Conteúdo</Label>
                        <Input
                            type='text'
                            value={content}
                            onChange={e=>{setContent(e.target.value)}}
                            invalid={
                                Boolean(props.error?.content)
                            }
                        />
                        <FormFeedback
                            //tooltip
                        >
                        {Array(props.error?.content ||[])?.map((err, idx)=>{
                            return  <React.Fragment key={idx}> {err}<br/></React.Fragment>
                        }) }
                    </FormFeedback>
                    </FormGroup></b>
                    { type === "MX" &&
                    <b><FormGroup
                        className='position-relative w-100'
                    >
                        <Label>Prioridade</Label>
                        <Input
                            type='number'
                            max="65535"
                            min="0"
                            value={priority}
                            onChange={e=>{setPriority(Math.max(0, Math.min(parseInt(e.target.value) || 0, 0xffff)).toString())}}
                            invalid={
                                Boolean(props.error?.content)
                            }
                        />
                        <FormFeedback
                            //tooltip
                        >
                        {Array(props.error?.content ||[])?.map((err, idx)=>{
                            return  <React.Fragment key={idx}> {err}<br/></React.Fragment>
                        }) }
                    </FormFeedback>
                    </FormGroup></b>
                    }
                </Col>
                <Col xs={12} md={2}>
                    <b><FormGroup
                        className='position-relative w-100'
                    >
                        <Label>TTL</Label>
                        <Input
                            type='number'
                            value={ttl}
                            onChange={e=>{setTTL(e.target.value)}}
                            invalid={
                                Boolean(props.error?.ttl?.length)
                            }
                        />
                        <FormFeedback
                            //tooltip
                        >
                        {Array(props.error?.ttl||[])?.map((err, idx)=>{
                            return  <React.Fragment key={idx}> {err}<br/></React.Fragment>
                        }) }
                    </FormFeedback>
                    </FormGroup></b>
                </Col>
                <Col xs={12} md={2} className="d-flex flex-column justify-content-center">
                    <Button
                        size='sm'
                        outline={true}
                        color="success"
                        className='mt-3 p-1 px-4'
                        onClick={e=>{
                            e.target.blur();
                            onSave(
                                {
                                    id: dns?.id,
                                    type:type,
                                    name:name,
                                    content:getContent(),
                                    ttl: parseInt(ttl),
                                    zone_id: zone.id
                                }
                            )
                        }}
                    >Salvar</Button>
                </Col>
            </Row>
        </td>
    </tr>


}


export default DNSForm;