import { autenticando, isAuthenticated } from '../../storage/slicers/accountingSlice';
import { isLoading } from '../../storage/slicers/applicationSlice';
import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import {LoadingIndicator} from "./loading";
import LoginScreen from "../pages/login";
import NavBarComponent from "./navbar";


const AppLayout = () => {
  const _isAuthenticated = useSelector(isAuthenticated)
  const _autenticando = useSelector(autenticando)
  const _loading = useSelector(isLoading)
  return (
    <div className='main-content'>
      
      <NavBarComponent isAuthenticated={_isAuthenticated} />
      {
        (_isAuthenticated)?
          <Outlet />
        :
          <LoginScreen autenticando={_autenticando} />
      }
      <LoadingIndicator loading={_loading}/>
    </div>
  )
};

export default AppLayout;