import getClient from './client';


export function loginAction(credenciais) {
    return new Promise((resolve, reject) =>{
      getClient().post(
        "/auth",
        {
          username: credenciais.username,
          password: credenciais.password
        }
      ).then((response)=>{
        resolve({
          data: {
            credentials: response.data.access_token,
            lastValidation: new Date().valueOf(),
            rootName: response.data.root_name
          }
        })
      }).catch((e)=>{
        reject({
          data: e
        })
      })
    });
  }
  