import React from 'react';
import {Modal, ModalBody} from "reactstrap";



export function LoadingIndicator(props){
    
    return <Modal centered={true} fade={false} isOpen={props.loading} toggle={()=>{}} cbackdrop="static">
    <ModalBody>
        Carregando...      
    </ModalBody>
  </Modal>
}