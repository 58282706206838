import { configureStore } from '@reduxjs/toolkit';
import accountingSlice from '../storage/slicers/accountingSlice';
import applicationSlice from "../storage/slicers/applicationSlice";
import modelsSlice from "../storage/slicers/modelsSlice";
import {authMiddleware} from "../storage/middlewares/store";

export const store = configureStore({
  reducer: {
    account: accountingSlice,
    application: applicationSlice,
    models: modelsSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware)
});
