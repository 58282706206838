import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateRecordTask, getEditRecordSavingStatus, clearUpdateRecordErros, deleteRecordTask} from '../../storage/slicers/modelsSlice';
import { DNSForm } from "./forms";
import {Button} from "reactstrap";



export function DNSRow(props){
    const {zone, dns} = props;
    const [editing, setEditing] = useState(false);

    const {saving, error} = useSelector(getEditRecordSavingStatus(dns?.id))
    const [isSaving, setIsSaving] = useState(saving)
    const [errors, setErrors] = useState(error)

    const dispatch = useDispatch();

    function onSave(dns_record){
        dispatch(
            updateRecordTask(dns_record)
        )
        setEditing(false)
    }

    useEffect(()=>{
        setIsSaving(saving)
    }, [saving])

    useEffect(()=>{
        setErrors(error)
    }, [error])

    function getEditing(){
        return Boolean(editing || isSaving || errors)
    }

    return <>
        <tr>
            <td style={{width: "10%"}} className="px-3"><b>{dns.type.toUpperCase()}</b></td>
            <td style={{width: "20%"}}>{dns.name}</td>
            <td style={{width: "20%"}}>{dns.content}</td>
            <td style={{width: "15%"}}>{dns.ttl}</td>
            <td style={{ width: "15%" }}>
                <div className="d-flex justify-content-around">
                    <Button
                        size='sm'
                        outline={true}
                        color={(saving)?"light":getEditing()?"danger":"default"}
                        className='m-0 p-1 j'
                        disabled={saving}
                        onClick={e=>{
                            e.target.blur();
                            setEditing(!getEditing())
                            dispatch(clearUpdateRecordErros(dns.id))
                        }}
                    >{(saving)?'Salvando...':getEditing()?'Cancelar':'Editar'}
                    </Button>
                    <Button
                        size='sm'
                        outline={true}
                        color={"default"}
                        className='m-0 p-1 text-danger'
                        onClick={
                            ()=>{
                                if(window.confirm(`Tem certeza que deseja deletar a entrada de DNS ${dns.fullname}? Essa ação é irreversível`)){
                                    dispatch(
                                        deleteRecordTask({
                                            ...dns,
                                            zone_id: zone.id
                                        })
                                    )
                                }
                            }
                        }
                    >Apagar</Button>
                </div>
            </td>
        </tr>
        {
            getEditing() &&
            <DNSForm
                onSave={onSave}
                saving={saving}
                error={error}
                zone={zone}
                dns={dns}
            />
        }
    </>
}