import React, { useEffect, useState } from 'react';
import {getClient} from "../../api";
import {
    Container, Row, Button, 
    Col, CardBody, Card, Table, Modal, ModalBody, ModalHeader
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listZoneTask, getZones, createZoneTask, getAddZoneSavingStatus } from '../../storage/slicers/modelsSlice';
import { ZoneForm } from "../components/forms";


function ZoneListPage(props){
    const dispatcher = useDispatch()
    const zones = useSelector(getZones)
    const navigate = useNavigate();
    const [adding, setAdding] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [errors, setErrors] = useState(null)
    const { saving, error } = useSelector(getAddZoneSavingStatus)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatcher(
            listZoneTask()
        )
    }, [])


    useEffect(() => {
        setErrors(error)
    }, [error])

    useEffect(() => {
        setIsSaving(saving)
    }, [saving])


    function getAdding(){
        return Boolean(adding || errors || isSaving)
    }

    return <>
        <Modal
            size='md'
            isOpen={getAdding()}
            toggle={()=>{
                setAdding(!getAdding())
                setErrors(null)
                setIsSaving(false)
            }}
        >
            <ModalHeader
                className="w-100 position-relative text-center"
                toggle={() => {
                    setAdding(!getAdding())
                    setErrors(null)
                    setIsSaving(false)
                }}
            >
                Adicionar Zona
            </ModalHeader>
            <ModalBody>
                {
                    <ZoneForm
                        onSave={(zone) => {
                            dispatch(
                                createZoneTask(zone)
                            )
                            setAdding(false)
                        }}
                        error={error}
                    />
                }
            </ModalBody>
        </Modal>
        <Container className="zone-container">
            <Row>
                <Col
                    xs='12'
                    className='py-2 px-1 text-end'
                >
                    <Button
                        className="m-0"
                        color={saving ? 'light' : getAdding() ? 'danger' : "primary"}
                        size="sm"
                        disabled={saving}
                        onClick={e => {
                            setAdding(!getAdding())
                            setErrors(null)
                            setIsSaving(false)
                            e.target.blur()
                        }}
                    >
                        {(saving ? 'Salvando...' : getAdding()) ? '- Cacelar' : '+ Adicionar'}
                    </Button>
                </Col>
                {
                    zones.map((itm,idx)=>{
                        return <Col
                            key={idx}
                            xs={12}
                            md={6}
                            className="zone-item"
                            onClick={e=>{
                                navigate(`/app/zone/${itm.id}`, {replace:true})
                            }}
                        >
                            <Card>
                                <CardBody>
                                    <p><b>{itm.name}</b></p>
                                    <p className="text-muted">{itm.is_active?"Ativo":"Inativo"}</p>
                                </CardBody>
                            </Card>
                        </Col>
                    })
                }
            </Row>
        </Container>
    </>
}


export default ZoneListPage;