import { createSlice } from '@reduxjs/toolkit';



const initialState = {
    loading: false
}


export const applicationSlicer = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setLoading:  (state, action) =>{
            state.loading = action.payload
        }
    },
    extraReducers: (builder) => {
      //builder
    },
});

export const isLoading = (state)=> state.application.loading;
export const { setLoading } = applicationSlicer.actions;
  
export default applicationSlicer.reducer;
