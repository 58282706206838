import {
    loginAction as _loginAction
}  from "./loginAPI";
import ZoneService from "./zoneAPI";
import RecordService from "./recordAPI";
import {default as _getClient } from './client';

export const getClient = _getClient;
export const loginAction = _loginAction;

export const createZone = ZoneService.create
export const listZone = ZoneService.list
export const readZone = ZoneService.read
export const updateZone = ZoneService.update
export const deleteZone = ZoneService.delete
export const enableZone = ZoneService.enable
export const disableZone = ZoneService.disable

export const createRecord = RecordService.create
export const listRecord = RecordService.list
export const readRecord = RecordService.read
export const updateRecord = RecordService.update
export const deleteRecord = RecordService.delete
export const enableRecord = RecordService.enable
export const disableRecord = RecordService.disable
