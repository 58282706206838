import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginTask} from '../../storage/slicers/accountingSlice';
import { getErrors } from '../../storage/slicers/accountingSlice';
import {
    Container, Row,
    Card, CardBody, Form,
    FormGroup, Label,
    Input, FormFeedback
} from 'reactstrap';


function LoginScreen(props){

    const dispatch = useDispatch();
    const errors = useSelector(getErrors)
    const [login, setLogin] = useState("")
    const [senha, setSenha] = useState("")


    function loginHandler(e){
        e.preventDefault()
        dispatch(
            loginTask({
                username: login,
                password: senha
            })
        )
    }

    return <Container className='py-5'>
            <Row className="justify-content-center">
                <div className="login-dialog">
                    <Card className="shadow border-0">
                        <CardBody className="px-lg-5 py-5">
                            <div className="text-center text-muted mb-4">
                                <h3>Fazer Login</h3>
                            </div>
                            <Form role="form" className="login-form">
                                <FormGroup className="form-group">
                                    <Label htmlFor="login">Login</Label>
                                    <Input
                                        id="login"
                                        value={login}
                                        onChange={e=>setLogin(e.target.value)}
                                        autoComplete="new-login"
                                        type="text"
                                        onKeyDown={
                                            e=>{
                                                if(e.key === "Enter" )
                                                    document.querySelector('#password').focus()
                                                return true
                                            }
                                        }
                                    />
                                    {
                                        errors?.login?.map((e,i)=>{
                                            return <FormFeedback key={i} valid={false}>
                                                {e}
                                            </FormFeedback>
                                        })
                                    }
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <Label htmlFor="password">Senha</Label>
                                    <Input
                                        invalid={
                                            Boolean(errors?.non_field_errors)
                                        }
                                        id="password"
                                        value={senha} 
                                        onChange={e=>setSenha(e.target.value)}
                                        onKeyDown={e=>{if(e.key === "Enter" )loginHandler(e); return true}}
                                        autoComplete="new-senha"
                                        type="password"/>
                                    {
                                        errors?.password?.map((e,i)=>{
                                            return <FormFeedback key={i} valid={false}>
                                                {e}
                                            </FormFeedback>
                                        })
                                    }
                                </FormGroup>
                                {
                                    errors?.non_field_errors?.map((e,i)=>{
                                        return <FormFeedback style={{display: 'block'}} key={i} valid={false}>
                                            {e}
                                        </FormFeedback>
                                    })
                                }
                                {
                                    errors?.generic_error?.map((e,i)=>{
                                        return <FormFeedback style={{display: 'block'}} key={i} valid={false}>
                                            {e}
                                        </FormFeedback>
                                    })
                                }
                                <div className="text-center">
                                    <button
                                        type="button"
                                        disabled={props.autenticando}
                                        onClick={loginHandler}
                                        className="my-4 btn btn-yellow submit">Entrar</button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Row>
       </Container>;
}


export default LoginScreen;