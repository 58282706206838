import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createZone, readZone, updateZone,
  deleteZone, enableZone, disableZone,
  createRecord, readRecord, updateRecord,
  deleteRecord, enableRecord, disableRecord,
  listRecord, listZone
} from '../../api';



const initialState = {
  zones: [],
  records: [],
  // erros
  createZoneTaskError: null,
  listZoneTaskError: null,
  readZoneTaskError: null,
  updateZoneTaskError: {},
  deleteZoneTaskError: null,
  enableZoneTaskError: null,
  disableZoneTaskError: null,
  createRecordTaskError: null,
  listRecordTaskError: null,
  readRecordTaskError: null,
  updateRecordTaskError: {},
  deleteRecordTaskError: null,
  enableRecordTaskError: null,
  disableRecordTaskError: null,
  // running
  createZoneTaskRunning: false,
  listZoneTaskRunning: false,
  readZoneTaskRunning: false,
  updateZoneTaskRunning: {},
  deleteZoneTaskRunning: false,
  enableZoneTaskRunning: false,
  disableZoneTaskRunning: false,
  createRecordTaskRunning: false,
  listRecordTaskRunning: false, 
  readRecordTaskRunning: false,
  updateRecordTaskRunning: {},
  deleteRecordTaskRunning: false,
  enableRecordTaskRunning: false,
  disableRecordTaskRunning: false,
};


export const createZoneTask = createAsyncThunk(
  'models/zone/create',
  async (zone, thapi)=>{
    try{
      const response = await createZone(zone)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const readZoneTask = createAsyncThunk(
  'models/zone/read',
  async (zone_id, thapi)=>{
    try{
      const response = await readZone(zone_id)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const listZoneTask = createAsyncThunk(
  'models/zone/list',
  async (query, thapi)=>{
    try{
      const response = await listZone(query)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const updateZoneTask = createAsyncThunk(
  'models/zone/update',
  async (zone, thapi)=>{
    try{
      const response = await updateZone(zone)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const deleteZoneTask = createAsyncThunk(
  'models/zone/delete',
  async (zone, thapi)=>{
    try{
      const response = await deleteZone(zone)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const enableZoneTask = createAsyncThunk(
  'models/zone/enable',
  async (zone, thapi)=>{
    try{
      const response = await enableZone(zone)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const disableZoneTask = createAsyncThunk(
  'models/zone/disable',
  async (zone, thapi)=>{
    try{
      const response = await disableZone(zone)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);


export const createRecordTask = createAsyncThunk(
  'models/record/create',
  async (record, thapi)=>{
    try{
      const response = await createRecord(record)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const readRecordTask = createAsyncThunk(
  'models/record/read',
  async (zone_id, record_id, thapi)=>{
    try{
      const response = await readRecord(zone_id, record_id)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const listRecordTask = createAsyncThunk(
  'models/record/list',
  async (options, thapi)=>{
    try{
      const { zone_id, query } = options
      const response = await listRecord(zone_id, query)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const updateRecordTask = createAsyncThunk(
  'models/record/update',
  async (record, thapi)=>{
    try{
      const response = await updateRecord(record)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const deleteRecordTask = createAsyncThunk(
  'models/record/delete',
  async (record, thapi)=>{
    try{
      const response = await deleteRecord(record)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const enableRecordTask = createAsyncThunk(
  'models/record/enable',
  async (record, thapi)=>{
    try{
      const response = await enableRecord(record)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);

export const disableRecordTask = createAsyncThunk(
  'models/record/disable',
  async (record, thapi)=>{
    try{
      const response = await disableRecord(record)
      return response.data;
    } catch(e){
    if(e?.data?.response?.headers?.['content-type'] === "application/json"){
      return thapi.rejectWithValue(e.data.response.data)
    }
    return thapi.rejectWithValue({
      "non_field_errors": [
        "Erro interno."
      ]
    })
  }
  }
);


export const modelsSlicer = createSlice({
    name: 'models',
    initialState,
  reducers: {
      clearUpdateZoneErros: (state, action) => {
        state.updateZoneTaskRunning[action.payload] = false
        state.updateZoneTaskError[action.payload] = null
      },
      clearUpdateRecordErros: (state, action) => {
        state.updateRecordTaskRunning[action.payload] = false
        state.updateRecordTaskError[action.payload] = null
      },
    },
    extraReducers: (builder) => {
      builder
      // pending
      .addCase(createZoneTask.pending, (state, action)=>{
        state.createZoneTaskRunning = true
        state.createZoneTaskError = null
      })
      .addCase(listZoneTask.pending, (state, action)=>{
        state.listZoneTaskRunning = true
        state.listZoneTaskError = null
      })
      .addCase(readZoneTask.pending, (state, action)=>{
        state.readZoneTaskRunning = true
        state.readZoneTaskError = null
      })
      .addCase(updateZoneTask.pending, (state, action)=>{
        state.updateZoneTaskRunning[action.meta.arg.id] = true
        state.updateZoneTaskError[action.meta.arg.id] = null
      })
      .addCase(deleteZoneTask.pending, (state, action)=>{
        state.deleteZoneTaskRunning = true
        state.deleteZoneTaskError = null
      })
      .addCase(enableZoneTask.pending, (state, action)=>{
        state.enableZoneTaskRunning = true
        state.enableZoneTaskError = null
      })
      .addCase(disableZoneTask.pending, (state, action)=>{
        state.disableZoneTaskRunning = true
        state.disableZoneTaskError = null
      })
      .addCase(createRecordTask.pending, (state, action)=>{
        state.createRecordTaskRunning = true
        state.createRecordTaskError = null
      })
      .addCase(listRecordTask.pending, (state, action)=>{
        state.listRecordTaskRunning = true
        state.listRecordTaskError = null
      })
      .addCase(readRecordTask.pending, (state, action)=>{
        state.readRecordTaskRunning = true
        state.readRecordTaskError = null
      })
      .addCase(updateRecordTask.pending, (state, action)=>{
        state.updateRecordTaskRunning[action.meta.arg.id] = true
        state.updateRecordTaskError[action.meta.arg.id] = null
      })
      .addCase(deleteRecordTask.pending, (state, action)=>{
        state.deleteRecordTaskRunning = true
        state.deleteRecordTaskError = null
      })
      .addCase(enableRecordTask.pending, (state, action)=>{
        state.enableRecordTaskRunning = true
        state.enableRecordTaskError = null
      })
      .addCase(disableRecordTask.pending, (state, action)=>{
        state.disableRecordTaskRunning = true
        state.disableRecordTaskError = null
      })
      //fullfiled
      // - Create
      .addCase(createZoneTask.fulfilled, (state, action)=>{
        state.createZoneTaskRunning = false
        state.createZoneTaskError = null
        state.zones.push(action.payload)
      })
      .addCase(createRecordTask.fulfilled, (state, action)=>{
        state.createRecordTaskRunning = false
        state.createRecordTaskError = null
        state.records.push(action.payload)
      })
      // - List
      .addCase(listZoneTask.fulfilled, (state, action)=>{
        state.listZoneTaskRunning = false
        state.listZoneTaskError = null
        state.zones = action.payload
      })
      .addCase(listRecordTask.fulfilled, (state, action)=>{
        state.listRecordTaskRunning = false
        state.listRecordTaskError = null
        state.records = action.payload
      })
      // - Read
      .addCase(readZoneTask.fulfilled, (state, action)=>{
        state.readZoneTaskRunning = false
        state.readZoneTaskError = null
        const idx = state.zones.findIndex(z=>z.id===action.payload.id)
        if(idx >= 0){
          state.zones[idx] = action.payload
        } else {
          state.zones.push(action.payload)
        }
      })
      .addCase(readRecordTask.fulfilled, (state, action)=>{
        state.readRecordTaskRunning = false
        state.readRecordTaskError = null
        state.records = [
          ...state.records || []
        ]
        const idx = state.records.findIndex(r=>r.id === action.payload.id)
        if(idx >= 0){
          state.records[idx] = action.payload
        } else {
          state.records.push(action.payload)
        }
      })
      // - Update
      .addCase(updateZoneTask.fulfilled, (state, action)=>{
        state.updateZoneTaskRunning[action.payload.id] = false
        state.updateZoneTaskError[action.payload.id] = null
        const idx = state.zones.findIndex(z=>z.id===action.payload.id)
        if(idx >= 0){
          state.zones[idx] = action.payload
        } else {
          state.zones.push(action.payload)
        }
      })
      .addCase(updateRecordTask.fulfilled, (state, action)=>{
        state.updateRecordTaskRunning[action.payload.id] = false
        state.updateRecordTaskError[action.payload.id] = null
        const idx = state.records.findIndex(r=>r.id === action.payload.id)
        if(idx >= 0){
          state.records[idx] = action.payload
        } else {
          state.records.push(action.payload)
        }
      })
      // - Delete
      .addCase(deleteZoneTask.fulfilled, (state, action)=>{
        state.deleteZoneTaskRunning = false
        state.deleteZoneTaskError = null
        const idx = state.zones.findIndex(z=>z.id===action.payload.id)
        if(idx >= 0){
          state.zones.splice(idx, 1)
        }
      })
      .addCase(deleteRecordTask.fulfilled, (state, action)=>{
        state.deleteRecordTaskRunning = false
        state.deleteRecordTaskError = null
        const idx = state.records.findIndex(r=>r.id === action.payload.id)
        if(idx >= 0){
          state.records.splice(idx, 1)
        }
      })
      // - Enable
      .addCase(enableZoneTask.fulfilled, (state, action)=>{
        state.enableZoneTaskRunning = false
        state.enableZoneTaskError = null
        const idx = state.zones.findIndex(z=>z.id===action.payload.id)
        if(idx >= 0){
          state.zones[idx] = {
            ...state.zones[idx],
            is_active: true
          }
        }
      })
      .addCase(enableRecordTask.fulfilled, (state, action)=>{
        state.enableRecordTaskRunning = false
        state.enableRecordTaskError = null
        const idx = state.records.findIndex(r=>r.id === action.payload.id)
        if(idx >= 0){
          state.records[idx] = {
            ...state.records[idx],
            is_active: true
          }
        }
      })
      // - Disable
      .addCase(disableZoneTask.fulfilled, (state, action)=>{
        state.disableZoneTaskRunning = false
        state.disableZoneTaskError = null
        const idx = state.zones.findIndex(z=>z.id===action.payload.id)
        if(idx >= 0){
          state.zones[idx] = {
            ...state.zones[idx],
            is_active: false
          }
        }
      })
      .addCase(disableRecordTask.fulfilled, (state, action)=>{
        state.disableRecordTaskRunning = false
        state.disableRecordTaskError = null
        const idx = state.records.findIndex(r=>r.id === action.payload.id)
        if(idx >= 0){
          state.records[idx] = {
            ...state.records[idx],
            is_active: false
          }
        }
      })
      // rejecteds
      .addCase(createZoneTask.rejected, (state, action)=>{
        state.createZoneTaskRunning = false
        state.createZoneTaskError = action.payload
      })
      .addCase(readZoneTask.rejected, (state, action)=>{
        state.readZoneTaskRunning = false
        state.readZoneTaskError = action.payload
      })
      .addCase(updateZoneTask.rejected, (state, action)=>{
        state.updateZoneTaskRunning[action.payload.id] = false
        state.updateZoneTaskError[action.payload.id] = action.payload
        console.log(action)
      })
      .addCase(deleteZoneTask.rejected, (state, action)=>{
        state.deleteZoneTaskRunning = false
        state.deleteZoneTaskError = action.payload
      })
      .addCase(enableZoneTask.rejected, (state, action)=>{
        state.enableZoneTaskRunning = false
        state.enableZoneTaskError = action.payload
      })
      .addCase(disableZoneTask.rejected, (state, action)=>{
        state.disableZoneTaskRunning = false
        state.disableZoneTaskError = action.payload
      })
      .addCase(createRecordTask.rejected, (state, action)=>{
        state.createRecordTaskRunning = false
        state.createRecordTaskError = action.payload
      })
      .addCase(readRecordTask.rejected, (state, action)=>{
        state.readRecordTaskRunning = false
        state.readRecordTaskError = action.payload
      })
      .addCase(updateRecordTask.rejected, (state, action)=>{
        state.updateRecordTaskRunning[action.meta.arg.id] = false
        state.updateRecordTaskError[action.meta.arg.id] = action.payload
        console.log(action)
      })
      .addCase(deleteRecordTask.rejected, (state, action)=>{
        state.deleteRecordTaskRunning = false
        state.deleteRecordTaskError = action.payload
      })
      .addCase(enableRecordTask.rejected, (state, action)=>{
        state.enableRecordTaskRunning = false
        state.enableRecordTaskError = action.payload
      })
      .addCase(disableRecordTask.rejected, (state, action)=>{
        state.disableRecordTaskRunning = false
        state.disableRecordTaskError = action.payload
      })
    },
});
  

export const getZones = (state) => state.models.zones || []
export const getZone = (zone_id) => (state) => (state.models.zones || []).filter(z=>z.id == zone_id)[0]
export const getRecords = (state) => state.models.records || []
export const getEditRecordSavingStatus =  (record_id)=> (state) => {
  let data = {
      saving: state.models.updateRecordTaskRunning[record_id],
      error: state.models.updateRecordTaskError[record_id]
  }
  console.log(data)
  return data
}
export const getAddRecordSavingStatus = (state) => ({
    saving: state.models.createRecordTaskRunning,
    error: state.models.createRecordTaskError
})
export const getAddZoneSavingStatus = (state) => ({
  saving: state.models.createZoneTaskRunning,
  error: state.models.createZoneTaskError
})

export const { clearUpdateZoneErros, clearUpdateRecordErros } = modelsSlicer.actions

export default modelsSlicer.reducer;
