import getClient from './client';



export default class ZoneService{

  static create(zone){
    return new Promise((resolve, reject) =>{
        getClient().post(
          "/zone",
          {
            ...zone,
            id: undefined
          }
        ).then((response)=>{
          resolve({
            data: response.data
            })
        }).catch((e)=>{
          reject({
            data: e
          })
        })
    });
  }

  static list(query){
      return new Promise((resolve, reject) =>{
          getClient().get(
            "/zone",
            {params: query}
          ).then((response)=>{
            resolve({
              data: response.data
              })
          }).catch((e)=>{
            reject({
              data: e
            })
          })
      });
  }
    
  static read(zone_id){
      return new Promise((resolve, reject) =>{
          getClient().get(
            "/zone/"+zone_id
          ).then((response)=>{
            resolve({
              data: response.data
              })
          }).catch((e)=>{
            reject({
              data: e
            })
          })
      });
  }

  static update(zone){
      return new Promise((resolve, reject) =>{
          getClient().patch(
            "/zone/"+zone.id,
            {
              ...zone,
              id: undefined
            }
          ).then((response)=>{
            resolve({
              data: response.data
              })
          }).catch((e)=>{
            reject({
              data: e
            })
          })
      });
  }

  static delete(zone){
      return new Promise((resolve, reject) =>{
          getClient().delete(
            "/zone/"+zone.id
          ).then((response)=>{
            resolve({data: zone.id})
          }).catch((e)=>{
            reject({
              data: e
            })
          })
      });
  }
  
  static enable(zone){
      return new Promise((resolve, reject) =>{
          getClient().patch(
            "/zone/"+zone.id+'/enable'
          ).then((response)=>{
            resolve({
              data: response.data
              })
          }).catch((e)=>{
            reject({
              data: e
            })
          })
      });
  }

  static disable(zone){
      return new Promise((resolve, reject) =>{
          getClient().patch(
            "/zone/"+zone.id+'/disable'
          ).then((response)=>{
            resolve({
              data: response.data
            })
          }).catch((e)=>{
            reject({
              data: e
            })
          })
      });
  }

}