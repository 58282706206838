import { createAsyncThunk, createSlice, current  } from '@reduxjs/toolkit';
import { loginAction } from '../../api';
import  secureLocalStorage  from  "react-secure-storage";



function getFromLocalStorage(){
  try {
    const data = JSON.parse(
      secureLocalStorage.getItem('_autentication_state')
    )
    if(data && data.credentials && data.lastValidation){
      return {
        credentials: data.credentials,
        lastValidation: data.lastValidation,
        rootName: data.rootName
      }
    }
  }catch(e){
  }
  return {}
}

const initialState = {
  autenticando: false,
  ...getFromLocalStorage()
};


export const loginTask = createAsyncThunk(
  'accounting/login',
  async (credentials,thapi) => {
    try{
      const response = await loginAction(credentials);
      return response.data;
    } catch(e){
      console.log(e);
      if(e?.data?.response?.headers?.['content-type'] === "application/json"){
        return thapi.rejectWithValue(e.data.response.data)
      }
      return thapi.rejectWithValue({
        "non_field_errors": [
          "Erro interno."
        ]
      })
    }
  }
);

  
export const accountingSlicer = createSlice({
    name: 'accounting',
    initialState,
    reducers: {
      authenticate: (state, action) => {
        state.autenticando = false;
        state.credentials =  action.payload.credentials;
        state.lastValidation = action.payload.lastValidation;
      },
      updateAuth: (state, action)=>{
        if(isAuthenticated({account: current(state)})){
          state.lastValidation = new Date().valueOf();
        }
      },
      logout: (state, action) =>{
        state.autenticando = false;
        state.credentials = null;
        state.lastValidation = null;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(loginTask.pending, (state, action) => {
          state.autenticando = true;
        })
        .addCase(loginTask.rejected, (state, action)=>{
          state.autenticando = false;
          state.errors = action.payload;
         
        })
        .addCase(loginTask.fulfilled, (state, action) => {
          state.errors = undefined;
          state.autenticando = false;
          state.credentials = action.payload.credentials;
          state.lastValidation = action.payload.lastValidation;
        });
    },
});
  
export const isAuthenticated = (state)=> (
    state.account.credentials &&
    state.account.lastValidation &&
    (new Date() - state.account.lastValidation) < (30 * 60 * 1000)
)

export const auth_token = (state)=> state.account.credentials

export const getErrors = (state) => state.account.errors

export const getRootName = (state) => state.account?.rootName || '.local'

export const autenticando = (state)=> state.account.autenticando
export const { updateAuth, authenticate, logout } = accountingSlicer.actions;
export default accountingSlicer.reducer;
