import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import AppLayout from "./components/Layout"
import ZoneListPage from "./pages/zone";
import DNSListPage from "./pages/dns";
import { useEffect } from "react";


function Redirect(){
    const nav = useNavigate()
    
    useEffect(()=>{
        nav('/app/zone', {replace: true})
    })
    return <>
    </>
}

export default function AppRoutes(){

    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<AppLayout />}>
                    <Route
                        path="*"
                        element={
                            <Redirect/>}
                    />
                    <Route
                        path="app/zone"
                        element={
                            <ZoneListPage/>
                        }
                    />
                    <Route
                        path="app/zone/:id/"
                        element={
                            <DNSListPage/>
                        }
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}